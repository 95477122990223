// @ts-ignore: Unreachable code error
declare var grecaptcha: ReCAPTCHA;

export class ContactForm {
    readonly RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';
    readonly RECAPTCHA_ID = '6Lcp_tsUAAAAAEtlyNLrrNPLHtUJIU_71F-e8qcZ';
    readonly IS_VISIBLE = 'is-v';
    readonly BLOCKED = 'blocked';

    isLoaded = false;


    public sendForm(form: HTMLFormElement) {
        let formData = new FormData(form);
        let data: { [key: string]: string; } = {};
        formData.forEach(function(value: string, key: string){
            data[key] = value;
        });
        var xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            if (xhr.readyState==4 && xhr.status==200){
                form.classList.toggle(this.BLOCKED);
                let response = JSON.parse(xhr.response);
                if(response.success) {
                    this.hideButton(form.querySelector('button'));
                    let span = form.querySelector('.success');
                    span.innerHTML = response.message;
                    span.classList.toggle(this.IS_VISIBLE);
                    let visibleErrors = form.querySelectorAll('.error.' + this.IS_VISIBLE);
                    visibleErrors.forEach((element) => {
                        element.classList.remove(this.IS_VISIBLE);
                    });
                } else {
                    for (const key in response.errors) {
                        if (Object.prototype.hasOwnProperty.call(response.errors, key)) {
                            let span = form.querySelector('.error.' + key);
                            if(span) {
                                span.innerHTML = response.errors[key];
                                if(span.classList.contains(this.IS_VISIBLE) == false) {
                                    span.classList.add(this.IS_VISIBLE);    
                                } 
                            }
                        }
                    }                        
                }
            }
        }

        xhr.open("POST", '/', true);
        xhr.send(formData);
    }

    public loadRaCaptchaOnClick(element: HTMLElement) {
         // @ts-ignore: Unreachable code error
         let target = element.closest('.contact-form') as HTMLFormElement;
         if(target) {
             this.LoadRecapcha();
             return true;
         }
 
         return false;
    }

    /**
     * LoadRecapcha
     */
    private LoadRecapcha() {
        if(this.isLoaded == false) {
            let scriptTag = document.createElement('script');
            scriptTag.src = this.RECAPTCHA_URL + this.RECAPTCHA_ID;

            scriptTag.onload = () => {
                const forms = document.querySelectorAll('.contact-form');
                forms.forEach((form) => {
                    this.configureCapcha(form as HTMLFormElement);
                }) 
            }
            document.body.appendChild(scriptTag);
            this.isLoaded = true;
        }
    }

    public configureCapcha(form: HTMLFormElement) {
        form.onsubmit = (event) => {
            event.preventDefault();
            form.classList.toggle(this.BLOCKED);

            grecaptcha.ready(() => {
                grecaptcha.execute(this.RECAPTCHA_ID, {action: 'create_comment'}).then((token: string) => {
                    const input = form.querySelector(".contact-recaptcha") as HTMLInputElement;
                    input && (input.value = token);
                    this.sendForm(form);
                });
            });
        };
    }

    private hideButton(button: HTMLButtonElement) {
        button.disabled = true;
        button.style.transitionProperty = 'opacity, visibility';
        button.style.transitionDuration = '500ms';
        button.style.opacity = '0';
        button.style.visibility = 'hiddden';
    }
}